<div class="container">
    <h1 class="my-4">Terms of Service</h1>
    <h3>ResilienceSoft IT Company</h3>
<h3>1. Introduction</h3>
<p>Welcome to <b>ResilienceSoft IT Company</b> ("Company," "we," "us," or "our"). These Terms of Service ("Terms") govern your use of our IT services, software development, consulting, and other offerings. By engaging with us, you agree to these Terms.</p>
<h3>2. Services</h3>
<p>We provide IT solutions, software development, cloud computing, and consulting services. The scope, timelines, and deliverables will be agreed upon in written contracts or service agreements.</p>
<h3>3. Client Responsibilities</h3>
<p>Clients agree to:</p>
<ul>
    <li>Provide accurate project requirements and timely feedback.</li>
    <li>Ensure compliance with relevant laws and regulations.</li>
    <li>
        Make payments as per agreed terms and conditions.</li>
</ul>
<h3>4. Payments & Refunds</h3>
<ul>
    <li>Payment terms will be outlined in each service contract.</li>
    <li>Late payments may incur penalties or service suspension.</li>
    <li>Refunds, if applicable, will be governed by our <b>Cancellation Policy</b>.</li>
</ul>
<h3>5. Intellectual Property (IP) Rights</h3>
<ul>
    <li>ResilienceSoft retains ownership of proprietary tools, frameworks, and pre-existing intellectual property.</li>
    <li>Custom software developed for clients will be transferred as per the agreed contract.</li>
    <li>Clients must not resell, distribute, or modify our IP without prior consent.</li>
</ul>
<h3>6. Confidentiality</h3>
<p>Both parties agree to protect each other's confidential information, including trade secrets, client data, and business strategies.</p>
<h3>7. Limitation of Liability</h3>
<ul>
    <li>
        We are not liable for indirect, incidental, or consequential damages arising from our services.
    </li>
    <li>Our total liability will not exceed the total fees paid by the client for the specific project.</li>
</ul>
<h3>8. Termination</h3>
<p>We reserve the right to terminate services if:</p>
<ul>
    <li>A client violates these Terms.</li>
    <li>Payments are overdue beyond the agreed period.</li>
    <li>Unforeseen circumstances prevent service delivery.</li>
</ul>
<h3>9. Governing Law</h3>
<p>These Terms shall be governed by and interpreted under the laws of <b>India</b>. Any disputes shall be settled in the jurisdiction of Bilaspur, Chhattisgarh, India.</p>
   
    <h3>10. Amendments</h3>
<p>We may update these Terms periodically. The latest version will be available on our website.</p>
<h3>11. Contact Us</h3>
<p>For any queries, contact:</p>
📧 Email: <a href="mailto:info@resiliencesoft.com">{{mail}}</a> 
</div>