<div class="container">
    <h1 class="my-4">Cancellation Policy for Foreign Income </h1>
<h2>ResilienceSoft IT Company</h2>
<h3>1. Scope</h3>
<p>This policy applies to all foreign income transactions, including software development services, IT consulting, and other contractual agreements involving international clients.</p>
<h3>2. Cancellation by the Client</h3>
<ul>
    <li><b>Before Project Initiation:</b></li>
    <ul>
        <li>
            If a client cancels before the project starts, a <b>10% administrative fee</b>  will be deducted from any advance payment made.
        </li>
    </ul>
    <li><b>After Project Initiation:</b>
        
    </li>
    <ul>
        <li>If the project is canceled <b>within the first 25% of the agreed timeline</b>, 50% of the total project cost will be charged.</li>
        <li>If the project is canceled <b>after 25% but before 75% of completion</b> , 75% of the total project cost will be charged.</li>
        <li>If the project is canceled <b>after 75% completion</b>, the full amount will be payable.</li>
    </ul>
</ul>
<h3>3. Cancellation by ResilienceSoft</h3>
<p>The company reserves the right to cancel a project due to unforeseen circumstances such as regulatory issues, non-compliance, or failure to provide required inputs from the client. In such cases:</p>
<ul>
    <li> <b>A full refund</b> will be issued if no work has been started.</li>
    <li> <b>A partial refund</b> will be processed based on the work completed if the project is already underway.</li>
</ul>
<h3>4. Refund Processing</h3>
<ul>
    <li>Refunds (if applicable) will be processed within <b>15 business days</b>  via the original payment method.</li>
    <li>Transaction fees, currency exchange losses, and other deductions imposed by payment processors are non-refundable.</li>
</ul>
<h3>5. Dispute Resolution</h3>
<p>Any disputes regarding cancellations and refunds will be resolved through mutual discussion. If unresolved, disputes will be settled under the jurisdiction of <b>Indian law</b>  or as per the terms specified in the service agreement.</p>
<h3>6. Contact for Cancellations</h3>
<p>For any cancellation requests or inquiries, please contact:</p>
📧 Email: <a href="mailto:info@resiliencesoft.com">{{mail}}</a> 
</div>