<div class="container">
    <h1 class="my-4">Privacy Policy</h1>
    <h3>ResilienceSoft IT Company</h3>
    <h3>1. Introduction</h3>
    <p>ResilienceSoft IT Company ("we," "our," or "us") is committed to protecting the privacy of our clients, partners, and visitors. This Privacy Policy explains how we collect, use, and safeguard your information when you engage with our services or visit our website.</p>
    <h3>2. Information We Collect</h3>
    <p>We may collect the following types of information:</p>
    <p><b>a. Personal Information</b></p>
    <ul>
        <li>Name, email address, phone number, and business details</li>
        <li>Billing and payment information</li>
        <li>Communication records</li>
    </ul>
    <p><b>b. Technical Information</b></p>
    <ul>
        <li>IP address and browser type</li>
        <li>Cookies and usage data</li>
        <li>Log files and analytics</li>
    </ul>
    <p><b>c. Project & Service Data</b></p>
    <ul>
        <li>Information related to IT services, software development, and consulting projects</li>
    </ul>
    <h3>3. How We Use Your Information</h3>
    <p>We use collected data for:</p>
    <ul>
        <li>Providing IT services and support</li>
        <li>Processing payments and invoices</li>
        <li>Improving website and service functionality</li>
        <li>Sending updates, promotions, or important service-related communications</li>
        <li>Ensuring compliance with legal and regulatory requirements</li>
    </ul>
    <h3>4. Data Sharing & Disclosure</h3>
    <p>We do <b>not</b> sell or trade your information. However, we may share data with:</p>
    <ul>
        <li><b>Third-party service providers </b> (e.g., payment processors, cloud storage providers)</li>
        <li><b>Legal authorities</b> if required by law or for fraud prevention</li>
        <li><b>Business partners</b> with prior consent</li>
    </ul>

<h3>5. Data Security</h3>
<p>We implement industry-standard security measures, including:</p>
<ul>
    <li>Encrypted transactions and secure data storage</li>
    <li>Access controls to prevent unauthorized access</li>
<li>Regular security audits and updates</li>
</ul>
<h3>6. Cookies & Tracking Technologies</h3>
<p>Our website may use cookies for analytics and user experience improvement. Users can manage cookie preferences through their browser settings.</p>
<h3>7. Your Rights</h3>
<p>You have the right to:</p>
<ul>
    <li>
        Request access, modification, or deletion of your personal data
    </li>
    <li>Opt out of marketing communications</li>
    <li>Withdraw consent for data processing where applicable</li>
</ul>
<h3>8. International Data Transfers</h3>
<p>For foreign clients, data may be transferred and stored in India or other jurisdictions where we operate. We ensure data protection compliance with applicable international standards.</p>
<h3>9. Updates to This Policy</h3>
<p>We may update this Privacy Policy periodically. The latest version will be available on our website.</p>
<h3>10. Contact Us</h3>
<p>For privacy-related inquiries, contact:</p>
📧 Email: <a href="mailto:info@resiliencesoft.com">{{mail}}</a> 
</div>