import { Component } from '@angular/core';

@Component({
  selector: 'app-cancellation-policy',
  standalone: true,
  imports: [],
  templateUrl: './cancellation-policy.component.html',
  styleUrl: './cancellation-policy.component.css'
})
export class CancellationPolicyComponent {
mail='info@resiliencesoft.com'
}
