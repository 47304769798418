<div class="container">
    <h1 class="my-4">Privacy Policy for Housie Mania Game</h1>



<h3>1. Introduction</h3>
<p>Welcome to Housie Mania! This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our game. By accessing and using Housie Mania, you consent to the practices described in this policy.</p>

<h3>2. Information We Collect</h3>

<p>2.1. Personal Information: We may collect personal information such as your name, email address, and in-game username when you register or use certain features of the game.</p>

<p>2.2. Non-Personal Information: We may also collect non-personal information such as device information, IP address, and gameplay data to improve the game's performance and user experience.</p>

<h3>3. How We Use Your Information</h3>

<p>3.1. Personal Information: We may use your personal information to:

    Create and manage your user account.
    Communicate with you regarding game updates, notifications, and support.
    Respond to your inquiries and feedback.</p>

<p>3.2. Non-Personal Information: We may use non-personal information for analytics, game improvement, and to personalize your gaming experience.</p>

<h3>4. Sharing Your Information</h3>

<p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:

    With service providers who assist us in providing the game.
    To comply with legal obligations or protect our rights and interests.</p>

<h3>5. Security</h3>

<p>We take appropriate measures to protect your personal information, but please be aware that no method of data transmission or storage is 100% secure. We cannot guarantee the security of your information.</p>

<h3>6. Cookies and Analytics</h3>

<p>We may use cookies and analytics tools to collect non-personal information about your usage of the game. These tools help us improve the game and provide a better experience.</p>

<h3>7. Children's Privacy</h3>
 
<p>Housie Mania is not intended for children under the age of 13. We do not knowingly collect or store personal information from children under 13.</p>

<h3>8. Changes to Privacy Policy</h3>

<p>We may update this Privacy Policy to reflect changes in our practices. Any updates will be posted on this page, and the "Last Updated" date will be revised accordingly.</p>

<h3>9. Contact Information</h3>

<p>If you have questions or concerns about this Privacy Policy, please contact us at app&#64;resiliencesoft.com</p>

<h3>10. Consent</h3>

<p>By using Housie Mania, you consent to this Privacy Policy.</p>
</div>